<template>
  <div class="container">
    <div class="img">
      <img src="./img/banner.png" alt="" />

    </div>

    <div class="zou"  >
      <div class="han">科研创新</div>
      <div class="ying">
        Scientific research
        innovation
      </div>
    </div>

    <div class="dao2" >
      <div>  <router-link :to="{name:'left'}">
        <span :class="{active:active=='left'}" @click="active='left'">科研体系</span>
            </router-link>
      </div>
      <div>
        <router-link :to="{name:'center'}">
          <span :class="{active:active=='center'}" @click="active='center'">科研实力</span>
        </router-link>
      </div>
        <div>
          <router-link :to="{name:'right'}">
           <span :class="{active:active=='right'}" @click="active='right'">科研成果</span>
          </router-link>
        </div>
    </div>
    <div class="xian"></div>

  </div>
</template>

<script>
  export default {
    name: "Index",
    data(){
      return{
        active:''
      }
    },
    watch:{
      $route:{
        deep:true,
        immediate:true,
        handler(newV,olV) {
          console.log(this.active)
          this.active = newV.name;
          console.log(newV,"hello大家好")
          console.log(this.active,"??????")
        }
      }
    }
  }
</script>

<style scoped>
  .img img {
    width: 100%;
    height: 48.6891vh;
  }
  .zou{
    width: 13.1979vw;
    height: 13.551vh;
    opacity: 0.8;
    background-color: #d7000f;
    position: absolute;
    margin-left: 5.5625vw;
    margin-top: -8.4633vh;
  }
  .han{
    width: 10.7083vw;
    height: 1.4063vw;
    font-size: 1.5625vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #ffffff;
    margin-top:0.6771vw ;
    margin-left: 0.9854vw;
  }
  .ying{
    width: 14.8125vw;
    height: 1.1979vw;
    font-size: 1.4vw;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    text-align: left;
    color: #ffffff;
    margin-left: 0.9854vw;
    margin-top: 0.7vw;
    line-height: 1.1vw;
  }

  .dao2{
    height:3.5937vw;
    margin-left: 40vw;
    line-height: 3.5937vw;
    display: flex;
  }
  .dao2 div{
    width: 6.5313vw;
    height: 1.0938vw;
    font-size: 1.1458vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #949090;
    /* padding-left: 2.0833vw; */
    padding-right: 2.0833vw;
    padding-bottom: 1.0417vw;
  }
  .dao2 div span{
    padding-bottom: 1.0417vw;
  }
  .xian{
    width: 93.0200vw;
    height: 0.0529vw;
    opacity: 0.6;
    background-color: #A2A2A2;
    margin-left: 3.4896vw;

  }
  span:hover{
    color: red;
    border-bottom: 0.15vw solid red;
  }

  .active{
    color: red;
    border-bottom: 0.15vw solid red;
  }
</style>